import Link from 'next/link'
import ArrowRight from './ArrowRight'
import { Swiper, SwiperSlide } from 'swiper/react'
import BlackFridayItem from './BlackFridayItem'
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper'
import ChevronNext from './ChevronNext'
import ChevronPrev from './ChevronPrev'
import { useState } from 'react'

SwiperCore.use([Pagination, Autoplay, Navigation])

const BlackFridayPromotion = ({
  backgroundBanners = [],
  productBanners = [],
}) => {
  const [swiper, setSwiper] = useState(null)

  if (backgroundBanners.length === 0 || productBanners.length === 0) {
    return <></>
  }

  const breakIndex = Math.ceil(productBanners.length / 2) || 1
  const productFirstRow = productBanners.slice(0, breakIndex)
  const productSecondRow = productBanners.slice(breakIndex)

  const backgroundFirstRow = backgroundBanners.slice(0, breakIndex)
  const backgroundSecondRow = backgroundBanners.slice(breakIndex)

  return (
    <div className="black-friday">
      <div className="container position-relative">
        <div className="black-friday__headerWrapper">
          <div className="black-friday__header">
            <h2>Everyday is Black Friday</h2>
            <div className="black-friday__header-separator" />
            <div className="black-friday__header-link">
              <Link href={'#'}>Топ хямдралтай цэсүүд</Link>
              <ArrowRight />
            </div>
          </div>
        </div>

        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          // loop
          grabCursor
          slidesPerView={1}
          spaceBetween={24}
          className="black-friday__swiper"
          pagination={{
            bulletActiveClass: 'black-friday__swiper-bullet-active',
            bulletClass: 'black-friday__swiper-bullet',
            el: '.black-friday__swiper-pagination',
            clickable: true,
          }}
          navigation={{
            nextEl: '.black-friday__swiper-nav-next',
            prevEl: '.black-friday__swiper-nav-prev',
          }}
          breakpoints={{
            400: {
              slidesPerView: 2,
            },
            700: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
          onSwiper={(swiper) => {
            setSwiper(swiper)
          }}
        >
          {swiper ? (
            <>
              {productFirstRow.map((item, ind) => (
                <SwiperSlide key={ind}>
                  <div className="black-friday__swiper-item-wrapper">
                    <BlackFridayItem
                      backgroundBanner={backgroundFirstRow[ind]}
                      productBanner={item}
                    />
                    <BlackFridayItem
                      backgroundBanner={backgroundSecondRow[ind]}
                      productBanner={productSecondRow[ind]}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </>
          ) : null}

          <div className="black-friday__swiper-pagination" />
        </Swiper>

        <div className="black-friday__swiper-nav-next">
          <ChevronNext />
        </div>
        <div className="black-friday__swiper-nav-prev">
          <ChevronPrev />
        </div>
      </div>
    </div>
  )
}

export default BlackFridayPromotion
