import React, { useCallback } from 'react'
import clsx from 'clsx'
import MobileGallery from './mobileGallery'
import DesktopGallery from './desktopGallery'
import useMediaQuery from 'components/hooks/useMediaQuery'
import ViewTitle from 'components/core/components/TextSection'

const TravelBanners = ({ travelBanners }) => {
  travelBanners = travelBanners?.sort((a, b) => {
    return a?.priority - b?.priority
  })
  // travelBanners = travelBanners
  //   ? travelBanners.sort((a, b) => {
  //       return a.priority - b.priority
  //     })
  //   : travelBanners

  const isMobile = useMediaQuery('(max-width: 767px)')
  const encodeImageURI = useCallback((image) => {
    return process.env.BASE_IMAGE_BANNER_URL + encodeURIComponent(image)
  })

  const banner1 = isMobile
    ? travelBanners?.[3]?.translations?.mn_MN
    : travelBanners?.[0]?.translations?.mn_MN
  const banner2 = travelBanners?.[1]?.translations?.mn_MN
  const banner3 = travelBanners?.[2]?.translations?.mn_MN
  const banner4 = isMobile
    ? travelBanners?.[0]?.translations?.mn_MN
    : travelBanners?.[3]?.translations?.mn_MN
  const banner5 = travelBanners?.[4]?.translations?.mn_MN

  return (
    <div
      className={clsx(
        'd-flex container flex-column gap-3 my-3 mb-5',
        isMobile && 'px-4',
        !isMobile && 'px-5'
      )}
    >
      <ViewTitle
        title={'ОНЦЛОХ АНГИЛАЛ'}
        subTitle={'Бүх бүтээгдэхүүн харах'}
        link={'/category/ayalal'}
      />
      <MobileGallery
        isMobile={isMobile}
        travelBanners={travelBanners}
        encodeImageURI={encodeImageURI}
        banner1={banner1}
        banner2={banner2}
        banner3={banner3}
        banner4={banner4}
        banner5={banner5}
      />
      <DesktopGallery
        isMobile={isMobile}
        travelBanners={travelBanners}
        encodeImageURI={encodeImageURI}
        banner1={banner1}
        banner2={banner2}
        banner3={banner3}
        banner4={banner4}
        banner5={banner5}
      />
    </div>
  )
}

export default TravelBanners
