import React from 'react'
import Link from 'next/link'
import TwoByOneBanners from './TwoByOneBanners'
import Banner5 from './banner5'
import Banner4 from './banner4'

const DesktopGallery = ({
  isMobile,
  encodeImageURI,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
}) => {
  const url = '/'
  return (
    <div
      className="d-none d-md-flex flex-row gap-4 w-100 overflow-hidden"
      style={{ maxHeight: '80vh' }}
    >
      <TwoByOneBanners
        isMobile={isMobile}
        encodeImageURI={encodeImageURI}
        banner1={banner1}
        banner2={banner2}
        banner3={banner3}
      />
      <div
        className="d-flex flex-column gap-4 "
        style={{ width: '35%', minHeight: '100%', maxHeight: '100%' }}
      >
        <Banner4
          banner={banner4}
          isMobile={isMobile}
          encodeImageURI={encodeImageURI}
        />
        <Banner5
          banner={banner5}
          isMobile={isMobile}
          encodeImageURI={encodeImageURI}
        />
      </div>
    </div>
  )
}

export default DesktopGallery
