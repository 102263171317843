import clsx from 'clsx'
import React from 'react'
import SquareBanner from './squareBanner'
import Link from 'next/link'
import Discount from './discount'

const TwoByOneBanners = ({
  isMobile,
  encodeImageURI,
  banner1,
  banner2,
  banner3,
}) => {
  const disc = banner1?.mainText?.split('&')?.[1] || undefined
  const mainText = banner1?.mainText?.split('&')?.[0] || ''
  return (
    <div
      className={clsx(
        'd-flex justify-content-between',
        isMobile && 'flex-row gap-2',
        !isMobile && 'flex-column gap-4'
      )}
      style={{
        width: isMobile ? '100%' : '65%',
        maxHeight: '100%',
      }}
    >
      <Link href={banner1?.url || '#'}>
        <div
          className={clsx(
            'overflow-hidden position-relative cursor-pointer',
            isMobile ? 'w-50 rounded-4' : 'w-100 h-50 rounded-5'
          )}
        >
          <img
            src={
              isMobile
                ? encodeImageURI(banner1?.mobileImageName)
                : encodeImageURI(banner1?.imageName)
            }
            alt={`travel-banner-${banner1?.id}`}
            className="w-100 h-100 object-fit-cover"
            style={{
              filter: 'brightness(0.9)',
            }}
            width={200}
            height={400}
          />
          {/* disc start */}
          <Discount disc={disc} isMobile={isMobile} />
          {/* disc end */}
          <span
            className="position-absolute text-white font-weight-bold flex-wrap text-wrap lh-1"
            style={{
              fontSize: isMobile ? '20px' : '35px',
              top: isMobile ? '15px' : '35px',
              left: isMobile ? '15px' : '25px',
            }}
            dangerouslySetInnerHTML={{
              __html: mainText,
            }}
          />

          {/* Бүгдийг харах button */}
          <button
            className="banner-btn position-absolute"
            style={{
              right: isMobile ? '15px' : '20px',
              bottom: isMobile ? '15px' : '20px',
              color: 'black',
            }}
          >
            Бүгдийг харах
          </button>
        </div>
      </Link>
      <div
        className={clsx(
          'd-flex ',
          isMobile && 'flex-column gap-2 w-50 h-100',
          !isMobile && 'flex-row gap-4 w-100 h-50'
        )}
      >
        <SquareBanner
          className={clsx('pb-1 pb-md-0', isMobile ? 'h-50' : 'h-100')}
          isMobile={isMobile}
          banner={banner2}
          encodeImageURI={encodeImageURI}
        />
        <SquareBanner
          className={clsx('pt-1 pt-md-0', isMobile ? 'h-50' : 'h-100')}
          isMobile={isMobile}
          banner={banner3}
          encodeImageURI={encodeImageURI}
        />
      </div>
    </div>
  )
}

export default TwoByOneBanners
