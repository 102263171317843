import Image from 'next/image'
import Link from 'next/link'
import isEmpty from '@/utils/isEmpty'

function LongBanner({ slide, number, shouldOnlyWorkOn }) {
  const placeholderImg = '/product-img-placeholder.svg'
  const mainSlide = slide?.translations?.mn_MN?.imageName
  const mobileMainSlide = slide?.translations?.mn_MN?.mobileImageName

  return (
    <section className={`long-banner`} style={{ marginTop: '-3rem' }}>
      {slide ? (
        <Link
          href={`${
            isEmpty(slide?.translations?.mn_MN?.url)
              ? '/'
              : slide?.translations?.mn_MN?.url
          }`}
        >
          <a className="container">
            {shouldOnlyWorkOn === 'browser' && (
              <div className="d-none d-sm-none d-md-block d-lg-block py-5 px-2 mx-5">
                <Image
                  src={
                    mainSlide
                      ? process.env.BASE_IMAGE_BANNER_URL + mainSlide
                      : placeholderImg
                  }
                  alt={
                    slide?.translations?.mn_MN?.mainText || 'Main Slide Image'
                  }
                  layout={'responsive'}
                  width={1600}
                  height={99}
                  sizes="(max-width: 768px) 100vw, max-width: 1200px) 50vw, 33vw"
                  objectFit="cover"
                  loading="eager"
                  placeholder="blur"
                  unoptimized
                  blurDataURL={
                    mainSlide
                      ? process.env.BASE_IMAGE_BANNER_URL + mainSlide
                      : placeholderImg
                  }
                  quality={85}
                  priority
                />
              </div>
            )}
            {shouldOnlyWorkOn === 'mobile' && (
              <div className="d-block d-sm-block d-md-none d-lg-none py-4">
                <Image
                  src={
                    mobileMainSlide
                      ? process.env.BASE_IMAGE_BANNER_URL + mobileMainSlide
                      : placeholderImg
                  }
                  alt={
                    slide?.translations?.mn_MN?.mainText || 'Main Slide Image'
                  }
                  layout={'responsive'}
                  width={650}
                  height={120}
                  sizes="(max-width: 768px) 100vw, max-width: 1200px) 50vw, 33vw"
                  objectFit="cover"
                  loading="eager"
                  placeholder="blur"
                  unoptimized
                  blurDataURL={
                    mobileMainSlide
                      ? process.env.BASE_IMAGE_BANNER_URL + mobileMainSlide
                      : placeholderImg
                  }
                  quality={85}
                  priority
                />
              </div>
            )}
          </a>
        </Link>
      ) : shouldOnlyWorkOn === 'browser' ? (
        <div className="d-none d-sm-none d-md-block d-lg-block py-5"></div>
      ) : shouldOnlyWorkOn === 'mobile' ? (
        <div className="d-block d-sm-block d-md-none d-lg-none py-4"></div>
      ) : null}
    </section>
  )
}
export default LongBanner
