import Link from 'next/link'
import React from 'react'

export default function TextSection({ title, subTitle, link, style}) {
  return (
    <div className="d-flex flex-column align-items-center gap-2">
          <p style={style} className="f-text-title">{ title }</p>
          <div className="d-line ms-1" />
          <Link href={link || "/"}>
          <div style={style} className="f-text">
            {subTitle}
            <img src="/static/images/ui/arrow-forward.svg" />
          </div>
          </Link>
        </div>
  )
}
